.Footer_Select_Music {
  position: fixed;
  width: 100%;
  bottom: 0vh;
  height: 5vh;
  z-index: 99999;
  background-color: #595959;

  a {
    color: #000000;
    background-color: #595959;
    text-align: center;
    letter-spacing: 5px;
    text-transform: uppercase;
    font-family: Consolas, sans-serif;
    font-size: 1.5rem;
    line-height: 5vh;
    display: block;
    width: 100%;
    z-index: 99999;
    height: 100%;
  }
}

@media only screen and (max-width: 700px) {
  .Footer_Select_Music {
    z-index: 99999;
    line-height: 5vh;
    position: fixed;
    bottom: 3vh;
    background-color: #595959;
    a {
      position: fixed;
      z-index: 99999;
      line-height: 6vh;
      background-color: #595959;
      margin-top: -50px;
    }
  }
}
