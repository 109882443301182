.footer-player {
  border-top: 1px solid #ffffff;
  position: fixed;
  bottom: 10px;
  width: 100%;
  height: 2vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 9999;
  margin-bottom: 20px;
  background-color: #ffffff;
  padding-left: 0;
  padding-top: 40px;
  padding-right: 5px;
  
  Button{
    z-index: 99999;
    background-color: rgb(255, 255, 255);
    padding: 1px;
    margin: 2px;
  }
  
  .playback {
    position: absolute;
    top: -15px;
    z-index: 999;
    left: 0;
    width: 100%;
    overflow: hidden;
    .playback-completed{
      width: 100%;
    }
    .MuiSlider-thumb{
      box-shadow: none !important;
    }
  }
}

@media  screen and (max-width: 700px){ 
  .curr-music-details {
    text-align: left;
    margin-right: 10px;
    padding-right: 0px;
    p:nth-child(1) {
      margin-top: 5px;
      font-weight: bolder;
      font-size: 17px;
    }

    p:nth-child(2) {
      display: none;
    }
  }
}

.curr-music-container {
  justify-content: flex-start !important;
  flex-grow: 1;
  height: 100%;
  .curr-music-cover{
    padding-left: 5px;
  }
  
}

.playback-controls {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  align-items: center;
  flex-basis: 40%;

  div {
    padding-right: 10px;
  }
}

.playback-widgets {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-basis: 30%;
  height: 100%;
  color: rgb(255, 255, 255);
  .timer {
    display: none;
  }
  .slider{
    width: 100px;
    margin: 0 0px;
  }
  .volume {
    padding-right: 10px;
  }
}


@media only screen and (max-width: 700px) {
  .footer-player {
    position: fixed;
    margin-bottom: 7.5vh;
    height: 60px;
    padding-bottom: 30px;
    .playback-controls {
      z-index: 9999;
      justify-content: flex-end;
      flex-grow: 0;
      margin-right: -10px;
      padding-left: 10px;
      height: 10px;
    }

    .playback-widgets {
      display: none;
    }

    p:last-child{
      display: none;
    }
  }
  .playback {
    height: 10px;
    position: relative;
    bottom: 0px;
  }
}

