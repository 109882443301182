.song-name{
  font-weight: bolder;
  font-size: 1.2rem;
  color: rgb(43, 43, 43);
}
.author-name{
  font-weight: lighter;
  color: gray;
  font-size: 15px;
  margin-bottom: 10px;
}