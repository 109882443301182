@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

*{
  box-sizing: border-box;
}
body{
  font-family: 'Montserrat', sans-serif;
  padding: 0;
/*  background-color: #e0e0e0;*/
}
h1,h2,h3,h4,h5,h6,p{
  margin: 0;
  padding: 0;
}
a{
  text-decoration: none;
}
button{
  text-transform: capitalize !important;
}
/* width */
::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fafafa00;

}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6d6d6d00;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a2d5f200;
}
