@media only screen and (max-width: 700px){
  .bottom-navigation {
  position: fixed;
  height: 9vh;
  width: 100%;
  display: flex;
  margin: auto;
  bottom: 0;
  padding: 0px;

  .bottom-navigation-link {
    position: relative;
    width: 100%;
    background-color: white;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    bottom: 0px;

    .BottomNavAction {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .label {
      display: none;
      z-index: 1000;
      position: absolute;
      bottom: 5px;
      color: #000000;
      margin-top: 5px;
      font-weight: 600;
    }

    .active {
      color: #00a2ff;
      background-color: #c4c4c4;
      border-radius: 10px;
      margin-bottom: 0px;
    }

    .active + .label {
      display: block;
    }
  }
}
}




@media only screen and (max-width: 700px) {
  .bottom-navigation {
    position: fixed;
    bottom: 0;
    z-index: 99999;
    border-radius: 20px;
    .bottom-navigation-link {
      svg{
        font-size: 30px;
      }
      .label {
        display: none;
      }
      .active + .label {
        display: none;
      }
    }
  }
}

@media only screen and (min-width: 700px) {
  .bottom-navigation{
    display: none;
  }
}