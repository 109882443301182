.aside-bar {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  width: 180px;
  overflow: auto;
  border-right: 1px solid #e0e0e0;
  margin-top: 0%;
  z-index: 99;
  .p1 {
    border-bottom: 1px solid #565656;
    padding: 10px;
    font-weight: bolder;
    user-select: none;
  }
  .lib-sub {
    padding: 10px;
    margin: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .MuiButton-startIcon{
      color: #000000;
    }
  }
  .aside-bar-container {
    display: flex;
    flex-flow: column;
    width: 100%;
    .p1{
      color: #000000;
    }
  }
  .playlist{
    flex: 1;
    .p1{
      border-top: 1px solid #5a5a5a;
    }
    .lib-sub:hover{
      background-color: #545454;
      color: white;
    }
  }
}

@media only screen and (max-width: 700px) {
  .aside-bar {
    display: none;
  }
}