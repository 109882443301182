.dropdown-profile {
  position: absolute;
  z-index: 100000;
  top: 67px;
  right: 7px;
  border-radius: 0 0 10px 10px;
  border: 1px solid #e0e0e0;
  user-select: none;
  display: flex;
  flex-direction: column;
  .MuiSvgIcon-root{
    color: #3b3b3b;
  }
}