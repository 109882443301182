@import url('https://fonts.googleapis.com/css2?family=Geologica:wght@300&family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Monomaniac+One&display=swap');

body {
  margin: 0;
  padding: 0;
}

body::-webkit-scrollbar {
  width: 6px;
}

body::-webkit-scrollbar-thumb {
  background-color: #6d6d6d;
}

body::-webkit-scrollbar-track {
  background-color: #6d6d6d;
}

#main {
  font-family: Consolas, sans-serif;
  background: #1e1717;
  background: linear-gradient(110deg, #434343 50%, #6d6d6d 50%);
  height: 100vh;
  text-align: center;
  display: flex;
  flex-flow: column wrap;
}

.nav-item {
  height: 10vh;
  display: flex;
  font-size: 1.3rem;
  margin-left: 2vh;
}

.navbar-brand {
  font-family: "Pacifico", cursive;
  color: #ffffff;
  font-size: 3rem;
}

.main-row {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

.main-row-img {
  flex-basis: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: -10px;
}

.head-phone-img {
  max-width: 80%;
  padding: 20px;
}

.main-row-text {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  flex-basis: 100%;
  flex-grow: 1;
}

@media only screen and (max-width: 700px) {
  .main{
    position: fixed;
    top: 0px;
    margin-bottom: -20px;
  }
  .main-row {
    height: 60%;
    width: 38%;
    justify-content: center;
    padding: 20px;
    align-items: center;
  }

  .main-row-img {
    scale: 1.5;
    width: 250%;
    flex-basis: 70%;
    align-items: center;
    margin-left: 30%;
    margin-top: -50%;
  }

  .main-row-text {
    width: 95%;
    display: flex;
    position: absolute;
    margin-top: 130%;
    justify-content: center;
    margin-left: 65%;
  }
}

h1 {
  text-align: center;
  font-family: 'Monomaniac One', sans-serif, cursive;
  font-size: 3rem;
  color: #fff;
}

p {
  color: #000000;
  font-size: 1.2rem;
  margin-top: 20px;
  font-family: 'Geologica', sans-serif;
}

.btn {
  font-family: Consolas, sans-serif;
  letter-spacing: 2px;
  border: none;
  background-color: #000000;
  color: #fff;
  font-size: 1.4rem;
  text-transform: uppercase;
  margin-top: 40px;
  margin-right: 20px;
  padding: 15px 20px;
  border-radius: 5px;
  transition: all 200ms ease-in-out;
}

.btn:hover {
  cursor: pointer;
  background-color: #ffffff;
  color: #000000;
}
