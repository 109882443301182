.Home-skeleton{
  height: 100vh;
  width: 100vh;
}
.home-music-container{
  display: flex;
  .sidebar-home{
    flex: 0.2;
  }
  .main-home{
    flex: 0.8;
  }
}
.current-large-banner{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 90vh;
}
@media only screen and (max-width: 700px) {
  .home-music-container {
    .sidebar-home{
      flex: 0;
    }
    .main-home{
      flex: 1;
    }
  }
}