.mob-top-navigation {
  height: 4vh;
  display: flex;
  flex-flow: row;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99999;
  margin-bottom: 50px;
  background-color: white;
}

@media only screen and (max-width: 700px) {
  .mob-top-navigation {
    height: 60px;
    padding: 5px;
    width: 100%;
    position: fixed;
  }
}

@media only screen and (min-width: 700px) {
  .mob-top-navigation{
    position: fixed;
    height: 90px;
    margin-top: 0px;
    margin-bottom: 0px;
    width: 100%;
    z-index: 99999;
  }
}

