@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

.Developer {
  background-color: rgb(255, 255, 255);
  margin-bottom: 40px;

  .Developer-head {
    text-transform: uppercase;
    background-color: #ffffff00;
    color: #000000;
    margin-left: 5%;
    margin-top: 40px;
    font-size: 1.9rem;
    text-align: center;
    font-weight: 900;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    span {
      display: flex;
      align-items: center;
    }
  }

  @media only screen and (max-width: 700px) {
    .Developer-head{
      padding: 5px;
    }

    .Developer-profile{
      padding: 10px;
    }

    .Developer-profileDetails{
      padding: 0px;
      margin: 0px;
      font-size: 10px; 
      p{
        margin-left: 0px;
        margin-right: 0px;
      }
    }
    
  }


  .Developer-profile {
    display: flex;
    flex-flow: row wrap;
    background-color: #e8e8e8;
    font-weight: 500;
    padding: 40px;
    margin-top: 40px;
    height: 90%;
    border-radius: 20px;
    .Developer-profileCard {

      text-align: center;
      flex: 1 1 200px;
      padding: 5px;
      
      .Card-details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        padding: 10px;
        background-color: #474747;
        h3, p {
          padding: 5px;
          text-transform: uppercase;
        }
        h3 {
          color: rgb(255, 255, 255);
          font-size: 1.7rem;
        }
        p{
          color: rgb(255, 255, 255);
          font-family: 'Poppins', sans-serif;
        }
      }
    }

    .Developer-profileDetails {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: #dadada;
      margin: 5px;
      margin-bottom: 40px;
      border-radius: 20px;
      p {
        color: black;
        padding: 10px;
        margin-left: 10px;
        margin-right: 10px;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-family: 'Poppins', sans-serif;
        font-weight: 900;
        font-size: larger;
      }
      .Card-btn {
        background-color: rgba(0, 0, 0, 0.75);
        padding: 10px;
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: 10px;
        margin-right: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        border-radius: 20px;

        a {
          padding: 15px;
          color: rgb(255, 255, 255);
          text-transform: lowercase;
        }

        a:hover {
          color: #000000;
          background-color: #ffffff;
        }

        a:nth-child(1) {
          background: #575757;
        }

        a:nth-child(2) {
          background: #575757;
        }

        a:nth-child(3) {
          background: #575757;
        }

        a:nth-child(4) {
          background: #575757;
        }
        
      }

    }

  }

}  
