.music-card-container{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  margin-top: 10px;
  
}
@media only screen and (max-width: 700px) {
  .music-card-container {
    padding: 0px;
    height: 70vh;
    margin: 5px;
    margin-top: 20px;
  }
  ::-webkit-scrollbar {
    display: none;
  }
}