.music-card {
  width: fit-content;
  padding: 10px;
  position: relative;
  z-index: 99; /* Ustawienie niższej wartości z-index */

  .music-card-cover {
    position: relative;
    display: flex;
    z-index: 99;

    img {
      width: 200px;
      height: 200px;
      border-radius: 20px;
    }

    .play-circle {
      position: absolute;
      visibility: hidden;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .MuiSvgIcon-root {
        font-size: 4rem;
      }
    }

    .hover-img {
      visibility: visible;
    }
  }

  .music-card-cover:hover {
    cursor: pointer;

    img {
      filter: brightness(0.34);
    }

    .play-circle {
      color: #fff;
      visibility: visible;
    }
  }
}

@media only screen and (max-width: 700px) {
  .music-card {
    padding: 5px;
    .music-card-cover {
      img {
        width: 150px;
        height: 150px;
      }
    }
  }
}